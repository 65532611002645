import { useTranslation } from "react-i18next"
import CTA from "../Shared/CTA/CTA"
import { contactReasons } from "../../../config";

const PreFooterText = () => {
	const { t } = useTranslation()

	return (
		<div className='pft'>
			<div className='max-width centered text-color-white pft__content-wrapper'>
				<div className='pft__content'>
					<h5 className='title-h5 font-wolf'>
						{t("contactCTASection.subtitle")}
					</h5>
					<CTA
						hasContactForm
						contactReason={contactReasons.contact}
						contactOnly
					>
						{t("contactCTASection.cta")}
					</CTA>
				</div>
			</div>
		</div>
	)
}

export default PreFooterText