const FullWidth = props => {
	return (
		<div id={props.id ? props.id : ''} className={`headline ${props.darkbg ? 'headline--dark-bg' : ''} ${props.smallpadding ? 'headline--smallpadding' : 'headline--bigpadding'} ${props.nopaddingbottom ? 'headline--no-pb' : ''}`}>
			<div className='headline__wrapper max-width'>
				<h2 className={`${props.smallheadline ? 'title-h4' : 'title-h2'} font-wolf`}>
					{props.headlinetext}
				</h2>
				<p>
					{props.sublinetext}
				</p>
			</div>
		</div>
	)
}

export default FullWidth