import { useTranslation } from "react-i18next"

const Popup = ({showPopup, setShowPopup }) => {
	const { t } = useTranslation()

	return (
		<div className={'section-padding popup' + (showPopup ? " active" : "")}>
            <div className="nav_btn_wrapper close" onClick={() => setShowPopup(false)}>
                <div className="navrow"></div>
                <div className="navrow"></div>
            </div>
			<div className="popup_content">
                <div className="form__wrapper-mobile" data-tf-live={t("form.data-link")}></div>
            </div>
		</div>
	)
}

export default Popup