export const localeIDs = [
	"nl-NL",
	"en-NL",
	"nl-BE",
	"fr-BE",
	"da-DK",
	"fi-FI",
	"no-NO",
	"sv-SE"
]

export const contactReasons = {
	contact: "Contact",
	learnMore: "LearnMore",
	requestDemo: "RequestDemo"
}

export const privacyLinks = {
	"nl-NL" : "https://www.hp.com/uk-en/home.html",
	"en-NL" : "https://www.hp.com/uk-en/home.html",
	"nl-BE" : "https://www.hp.com/be-nl/home.html",
	"fr-BE" : "https://www.hp.com/be-fr/home.html",
	"da-DK" : "https://www.hp.com/dk-da/home.html",
	"fi-FI" : "https://www.hp.com/uk-en/home.html",
	"no-NO" : "https://www.hp.com/no-no/privacy/privacy-central.html",
	"sv-SE" : "https://www.hp.com/se-sv/privacy/privacy-central.html"
}