import { useState } from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import { localeIDs } from "../../../config"
import i18next from "i18next"

import { StyledCountryList } from "./styles"

import "./flags.css"

export const CountryList = ({ currLang, className }) => {

	let regionNames = new Intl.DisplayNames([currLang], { type: "region" })
	let languageNames = new Intl.DisplayNames([currLang], { type: "language" })

	return (
		<div className={className}>
			<ul>
				{localeIDs.map(localeID => {
					let langCode = localeID.split("-")[0]
					let regionCode = localeID.split("-")[1]

					let language = languageNames.of(langCode)
					let region = regionNames.of(regionCode)

					return (
						<li key={localeID}>
							<Link
								to={`/${localeID}`}
								onClick={() => {
									i18next.changeLanguage(langCode)
									// Language change becomes more obvious when page reloads.
									// This also fixes an issue where the partner price list state
									// won't update (SV - XX).
									window.location.pathname = `/${localeID}`
								}}
							>
								<span
									className={`fi fi-${regionCode.toLowerCase()}`}
								></span>
								<span className='name'>
									{region} / {language}
								</span>
							</Link>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

const LangPicker = ({ className }) => {

	const [open, setOpen] = useState(false)

	const location = useLocation()
	let currLocaleID = location.pathname.replace("/", "")

	let currLang = ""
	let currCountry = ""
	if (currLocaleID) {
		currLang = currLocaleID.split("-")[0].toLowerCase()
		currCountry = currLocaleID.split("-")[1].toLowerCase()
	}

	return (
		<div
			className={`${className}${open ? " open" : ""}`}
			onMouseLeave={() => setOpen(false)}
		>
			<button
				className='button white rounded'
				onClick={() => {
					setOpen(!open)
				}}
			>
				<span className={`fi fi-${currCountry}`}></span>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					height='24px'
					viewBox='0 0 24 24'
					width='24px'
					fill='#000000'
				>
					<path d='M24 24H0V0h24v24z' fill='none' opacity='.87' />
					<path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z' />
				</svg>
			</button>
			{open && <StyledCountryList currLang={currLang}/>}
		</div>
	)
}

export default LangPicker
