import { useLocation } from "react-router"
import { useTranslation } from "react-i18next"

import PartnerSection from "./PartnerSection/PartnerSection"
import ProductList from "./ProductList/ProductList"
import HeroImage from "./Hero/HeroImage"
import TabContentImageSwitch from "./TabContentImageSwitch/TabContentImageSwitch"
import HeadlineFullWidth from "./Headlines/FullWidth"
import BigContentArea from "./BigContentArea/BigContentArea"
import ProductClickArea from "./ProductClickArea/ProductClickArea"
import ImageText from "./ImageText/ImageText"
import FeatureGrid from "./FeatureGrid/FeatureGrid"
import PreFooterText from "./PreFooter/Text"
import Footer from "./Footer/Footer"

const HTML = () => {
	const { t } = useTranslation()

	const location = useLocation()
	let currLocaleID = location.pathname.replace("/", "")

	return (
		<div id='body'>
			<HeroImage locale={currLocaleID} imagealignment="right" />

			<FeatureGrid />
            
			<PartnerSection headline={(t("partnersection.headline"))} />

			<HeadlineFullWidth headlinetext={t("teaserSection.title")} />

			<TabContentImageSwitch />

			<HeadlineFullWidth headlinetext={t("products.headline")} nopaddingbottom id="products"/>

			<ProductList />

			<ImageText imagealignment="left" />

			<HeadlineFullWidth headlinetext={t("bigContentArea.title")} darkbg smallpadding />

			<BigContentArea />

			<HeadlineFullWidth headlinetext={t("productClickArea.title")} sublinetext={t("productClickArea.subTitle")} smallheadline nopaddingbottom />

			<ProductClickArea />

			<PreFooterText />


			<Footer locale={currLocaleID} />

		</div>
	)
}

export default HTML
