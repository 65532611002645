import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";


const NavigationMobile = ({ showMenu, setShowMenu }) => {
        const { t } = useTranslation()
        useEffect(() => {

        });
        return (
                <>
                        <div className={"navigation-mobile " + (showMenu ? "active" : "")}>
                                <div className="nav-mobile">
                                        <div>
                                                <div className="nav_btn_wrapper close" onClick={() => setShowMenu(false)}>
                                                        <div className="navrow"></div>
                                                        <div className="navrow"></div>
                                                </div>
                                        </div>
                                </div>
                                <a href="#hfs" onClick={() => setShowMenu(false)}>{t("navigation.navPoint1")}</a>
                                <a href="#partner" onClick={() => setShowMenu(false)}>{t("navigation.navPoint2")}</a>
                                <a href="#products" onClick={() => setShowMenu(false)}>{t("navigation.navPoint3")}</a>
                                <a href="#software" onClick={() => setShowMenu(false)}>{t("navigation.navPoint4")}</a>
                               <a href="#bca" onClick={() => setShowMenu(false)}>{t("navigation.navPoint5")}</a>
                        </div>
                </>
        )
}

export default NavigationMobile
