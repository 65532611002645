import { useState } from "react"
import { useTranslation } from "react-i18next"
import HPDialog from "../HPDialog"
import {
	Menu,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Box,
} from "@mui/material"
import ChevronRight from "@mui/icons-material/ChevronRight"

const CTA = ({
	 hasContactForm,
	 printPartners,
	 partners,
	 id,
	 filePath,
	 children,
	 variant,
	 contactReason,
	 contactOnly // <- this is used in order to differentiate between the pure contact poup and the request demo popup in order to display another headline for DK
 }) => {
	const [priceListOpen, setPriceListOpen] = useState(false)
	const [priceListAnchorEl, setPriceListAnchorEl] = useState(null)
	const [contactFormOpen, setContactFormOpen] = useState(false)

	const { t } = useTranslation();

	// Display contact form or price list, whatever is required
	const clickHandler = (event) => {

		// Variant = Contact Form
		if (hasContactForm) {
			setContactFormOpen(true)
		}

		// Variant = Partner List
		if (partners) {
			setPriceListOpen(true)
			setPriceListAnchorEl(event.currentTarget)
		}

		// Variant = Partner List
		if (printPartners) {
			setPriceListOpen(true)
			setPriceListAnchorEl(event.currentTarget)
		}
	}

	// Count configured links of each partner for the current product ID
	const getPartnerLinkCountForID = () => {
		let linkCount = 0

		if (Object.keys(partners).length) {
            // eslint-disable-next-line
			Object.keys(partners).map( partner => {
				if (partners[partner].links.products && partners[partner].links.products[id]) {
					linkCount++
				}
			})
		}
		return linkCount
	}

	const getCTAs = () => {

		// if the button type requires a list of partners, but there are no
		// links configured: Don't show the cta.

		if (partners && (getPartnerLinkCountForID() === 0)) {
			return null
		} else {
			if (variant === "blue") {
				return (
					<button
						className='button primary rounded modal'
						onClick={event => clickHandler(event)}
					>
						{children}
					</button>
				)
			} else {
				return (
					<div className='cta-wrapper cta-dark'>
						<button
							className='button rounded primary'
							onClick={event => clickHandler(event)}
						>
							{children}
						</button>
					</div>
				)
			}
		}
	}

	// Get the the link of this product for a given partner
	const getPartnerLink = (partner) => {
		if (!partner) throw Error("No partner provided")
		// Partner doesn't have any products defined
		if (!partners[partner].links.products) return undefined
		return partners[partner].links.products[id]
	}

	return (
		<>
			{/* CTA */}

			{getCTAs()}

			{/* Dialog */}

			{hasContactForm && (
				<HPDialog
					onClose={() => setContactFormOpen(false)}
					title={
						filePath
							? t('generic.form.titleWhitePaper')
							: t('generic.form.titleContact')
					}
					filePath={filePath}
					open={contactFormOpen}
					contactReason={contactReason}
					contactOnly={contactOnly}
				/>
			)}

			{(partners && getPartnerLinkCountForID()) ? (
				<Menu
					id='basic-menu'
					anchorEl={priceListAnchorEl}
					open={priceListOpen}
					onClose={() => setPriceListOpen(false)}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					<List dense={true}>
						{partners && Object.keys(partners).map((partner, index) => {
							return getPartnerLink(partner) ? (
								<a href={getPartnerLink(partner)} key={index} target="_blank"  rel="noreferrer">
									<ListItem
										style={{ paddingRight: "120px" }}
										secondaryAction={
											<Box
												display='flex'
												alignItems='center'
											>
												<IconButton
													edge='end'
													aria-label='delete'
												>
													<ChevronRight />
												</IconButton>
											</Box>
										}
									>
										<ListItemText
											primary={
												partners[partner].name || ""
											}
										/>
									</ListItem>
								</a>
							) : null
						})}
					</List>
				</Menu>
			) : null}

			{printPartners ? (
			<Menu
				id='basic-menu'
				anchorEl={priceListAnchorEl}
				open={priceListOpen}
				onClose={() => setPriceListOpen(false)}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<List dense={true}>
					{Object.keys(printPartners).map((partner, index) => {
						return printPartners[partner].links.print ? (
							<a href={printPartners[partner].links.print} key={index} target="_blank"  rel="noreferrer">
								<ListItem
									style={{ paddingRight: "120px" }}
									secondaryAction={
										<Box
											display='flex'
											alignItems='center'
										>
											<IconButton
												edge='end'
												aria-label='delete'
											>
												<ChevronRight />
											</IconButton>
										</Box>
									}
								>
									<ListItemText
										primary={
											printPartners[partner].name || ""
										}
									/>
								</ListItem>
							</a>
						) : null
					})}
				</List>
			</Menu>
		) : null}
		</>
	)
}

export default CTA
