export const partners = {
	"nl-BE": {
		bechtle: {
			name: "Bechtle BE",
			links: {
				products: {
					1: "https://www.bechtle.com/be-nl/finder/product-family/hp-dragonfly-g4-laptop--4785--f",
					2: "https://www.bechtle.com/be-nl/finder/product-family/hp-elitebook-840-g10-laptop--4796--f?query=HP+elitebook+840+g10",
					3: "https://www.bechtle.com/be-nl/finder/product-family/hp-elitebook-640-g10-laptop--4757--f",
				},
				home: "https://www.bechtle.com/be-nl/finder?query=HP",
				print:
					"https://www.bechtle.com/be-nl/shop/hardware/printen/printen--10009005--c/hp--m",
			},
		},
		// Changed to Dustin, but product links stay the same!!
		centralpoint: {
			name: "Dustin",
			links: {
				products: {
					1: "https://www.dustin.be/nl/zoeken/dragonfly%20g4/",
					2: "https://www.dustin.be/nl/zoeken/Hp%20elitebook%20840%20g10/",
					3: "https://www.dustin.be/nl/zoeken/hp%20elitebook%20640/",
				},
				home: "https://www.dustin.be/nl/hp/",
				print: "https://www.centralpoint.be/nl/multifunctionals/?brandName=HP",
			},
		},
		conxion: {
			name: "Conxion",
			links: {
				products: {
					1: "https://www.datatech.be/search/adjustsearch?search=hp+elite+dragonfly&searchtop=Zoeken&searchtype=fw&group=STK",
					2: "https://www.datatech.be/search/adjustsearch?search=HP+EliteBook+840",
					3: "https://www.datatech.be/search/adjustsearch?search=HP+EliteBook+640",
				},
				home: "https://www.datatech.be/store/pbrandlist/hewlett-packard/store-288-1170?t=b",
				print: "https://www.datatech.be/productfinders/printers/hp?flang=nl",
			},
		},
		coolblue: {
			name: "Coolblue",
			links: {
				products: {
					1: "https://www.coolblue.be/nl/laptops/hp/laptops-voor-zakelijk-gebruik",
					2: "https://www.coolblue.be/nl/laptops/hp",
					3: "https://www.coolblue.be/nl/laptops/hp",
				},
				home: "https://www.coolblue.be/nl/laptops/hp",
				print: "https://www.coolblue.be/nl/printers/hp",
			},
		},
		perfiction: {
			name: "BIASS/Perfiction",
			links: {
				products: {},
			},
		},
		realdolmen: {
			name: "Inetum-Realdolmen",
			links: {
				products: {
					//1: "https://store.realdolmen.com/search/string?search_str=Hp+elite+dragonfly+",
					2: "https://store.realdolmen.com/search/string?search_str=hp+elitebook+840+",
					3: "https://store.realdolmen.com/search/string?search_str=elitebook+640&flang=nl",
				},
				home: "https://store.realdolmen.com/search/string?search_str=HP+elitebook+840+g8",
				print:
					"https://store.realdolmen.com/search/string?search_str=HP+printer&group=STK",
			},
		},
		redcorp: {
			name: "Redcorp",
			links: {
				products: {
					1: "https://www.redcorp.com/nl/Search/Index?q=hp%20elite%20dragonfly",
					2: "https://www.redcorp.com/nl/Search/Index?q=hp%20elitebook%20840%20g10",
					3: "https://www.redcorp.com/nl/Search/Index?q=hp%20elitebook%20640%20g10",
				},
				home: "https://www.redcorp.com/nl/categorie/13/computers-hp",
				print: "https://www.redcorp.com/nl/categorie/50/printers-hp",
			},
		},
		vanroey: {
			name: "VanRoey.be",
			links: {
				products: {
					//1: "https://webstore.be/catalogsearch/result/?q=hp+elite+dragonfly",
					2: "https://webstore.be/catalogsearch/result/?q=hp+elitebook+840",
					3: "https://webstore.be/hp-elitebook-640-g10-laptop-35-6-cm-14-full-hd-intelr-coretm-i5-i5-1335u-16-gb-ddr4-sdram-512-gb-s",
				},
				home: "https://webstore.be/hp-elitebook",
				print: "https://webstore.be/print-scan/printers?merk=5431",
			},
		},
	},
	"fr-BE": {
		bechtle: {
			name: "Bechtle FR",
			links: {
				products: {
					1: "https://www.bechtle.com/be-fr/finder?query=HP%20elite%20dragonfly%20g4&origin=searchbar",
					2: "https://www.bechtle.com/be-fr/finder?query=HP%20elitebook%20840%20g10&origin=searchbar",
					3: "https://www.bechtle.com/be-fr/finder/product-family/ordinateurs-portables-hp-elitebook-640-g10--4757--f",
				},
				home: "https://www.bechtle.com/be-fr/finder?query=HP",
				print:
					"https://www.bechtle.com/be-fr/shop/materiel/imprimantes/imprimantes--10009005--c/hp--m",
			},
		},
		// Changed to Dustin, but product links stay the same!!
		centralpoint: {
			name: "Dustin",
			links: {
				products: {
					1: "https://www.dustin.be/fr/rechercher/dragonfly%20g4/",
					2: "https://www.dustin.be/fr/rechercher/hp%20840%20g10/",
					3: "https://www.dustin.be/fr/rechercher/hp%20640%20g10/",
				},
				home: "https://www.dustin.be/fr/hp/",
				print: "https://www.centralpoint.be/fr/multifonctions/?brandName=HP",
			},
		},
		conxion: {
			name: "Conxion",
			links: {
				products: {
					1: "https://www.datatech.be/search/adjustsearch?search=hp+elite+dragonfly&searchtop=Zoeken&searchtype=fw&group=STK&flang=fr",
					2: "https://www.datatech.be/search/adjustsearch?search=HP+EliteBook+840&flang=fr",
					3: "https://www.datatech.be/search/adjustsearch?search=HP+EliteBook+640&autocomplet_result=&searchtype=fw&searchtop=Rechercher",
				},
				home: "https://www.datatech.be/store/pbrandlist/hewlett-packard/store-288-1170?t=b",
				print: "https://www.datatech.be/productfinders/printers/hp&flang=fr",
			},
		},
		coolblue: {
			name: "Coolblue",
			links: {
				products: {
					1: "https://www.coolblue.be/fr/ordinateurs-portables/hp/ordinateurs-portables-a-usage-professionnel",
					2: "https://www.coolblue.be/fr/ordinateurs-portables/hp/ordinateurs-portables-a-usage-professionnel",
					3: "https://www.coolblue.be/fr/ordinateurs-portables/hp/ordinateurs-portables-a-usage-professionnel",
				},
				home: "https://www.coolblue.be/fr/ordinateurs-portables/hp",
				print: "https://www.coolblue.be/fr/imprimantes/hp",
			},
		},
		perfiction: {
			name: "BIASS/Perfiction",
			links: {
				products: {},
			},
		},
		realdolmen: {
			name: "Inetum-Realdolmen",
			links: {
				products: {
					//1: "https://store.realdolmen.com/search/string?search_str=Hp+elite+dragonfly+&flang=fr",
					2: "https://store.realdolmen.com/search/string?search_str=HP+elitebook+840",
					3: "https://store.realdolmen.com/search/string?search_str=HP+elitebook+",
				},
				home: "https://store.realdolmen.com/search/string?search_str=HP+elitebook+840+g8",
				print:
					"https://store.realdolmen.com/search/string?search_str=HP+printer&group=STK&flang=fr",
			},
		},
		redcorp: {
			name: "Redcorp",
			links: {
				products: {
					1: "https://www.redcorp.com/fr/Search/Index?q=hp%20elite%20dragonfly",
					2: "https://www.redcorp.com/fr/Search/Index?q=hp%20elitebook%20840%20g10",
					3: "https://www.redcorp.com/fr/Search/Index?q=hp%20elitebook%20640%20g10",
				},
				home: "https://www.redcorp.com/fr/categorie/13/computers-hp",
				print: "https://www.redcorp.com/fr/categorie/50/printers-hp",
			},
		},
		vanroey: {
			name: "VanRoey",
			links: {
				products: {},
			},
		},
	},
	"da-DK": {
		ATEA: {
			name: "ATEA",
			links: {
				products: {
					1: "https://www.atea.dk/eshop/product/hp-dragonfly-g4-notebook-13-5-intel-core-i5-1335u-evo-16-gb-ram-512-gb-ssd-pan-nordic/?prodid=5864290",
					2: "https://www.atea.dk/eshop/product/hp-elitebook-840-g10-notebook-14-core-i5-1335u-16-gb-ram-256-gb-ssd-pan-nordic/?prodid=5847369",
					3: "https://www.atea.dk/eshop/product/hp-elitebook-640-g10-notebook-14-core-i5-1335u-16-gb-ram-256-gb-ssd-pan-nordic/?prodid=5851014",
				},
				home: "https://www.atea.dk/eshop/manufacturers/hp-inc/",
				print:
					"https://www.atea.dk/eshop/products/printere-og-scannere/printere/multifunktionsprintere/?filters=A4_205%7EM_898_24",
			},
		},
		Dustin: {
			name: "Dustin",
			links: {
				products: {
					1: "https://www.dustin.dk/product/5011338261/dragonfly-g4",
					2: "https://www.dustin.dk/product/5011334457/elitebook-840-g10",
					3: "https://www.dustin.dk/product/5011334445/elitebook-640-g10",
				},
				home: "https://www.dustin.dk/brands/hp/",
				print:
					"https://www.dustin.dk/group/hardware/printere-tilbehor/multifunktionsprintere/blakprintere/blakprintere-a4?manufacturer=hp",
			},
		},
		Advania: {
			name: "Advania",
			links: {
				products: {
					1: "https://eshop.advania.dk/product/Computere/Brbare/HP/HP-Dragonfly-G4-Notebook-13-5-Intel-Core-i5-1?prodid=6038497",
					2: "https://eshop.advania.dk/product/Computere/Brbare/HP/HP-EliteBook-840-G10-Notebook-14-Intel-Core-i?prodid=6036462",
					3: "https://eshop.advania.dk/product/Computere/Brbare/HP/HP-EliteBook-640-G10-Notebook-14-Intel-Core-i?prodid=5729062",
				},
				home: "https://eshop.advania.dk/cms/Hp_inc",
				print:
					"https://eshop.advania.dk/product_list/Printere-scannere?instock=true&manufacturer=HP&page=1&recommended=false&sortby=0",
			},
		},
		Edgemo: {
			name: "Edgemo",
			links: {
				products: {
					1: "https://eshop.edgemo.com/hp-inc/hp-dragonfly-g4-notebook-135%22-intel-core-i5-1335u-evo-16-gb-ram-512-gb-ssd-pan-nordic-p3293500",
					2: "https://eshop.edgemo.com/hp-inc/hp-elitebook-840-g10-notebook-14%22-core-i5-1335u-16-gb-ram-256-gb-ssd-pan-nordic-p3278216",
					3: "https://eshop.edgemo.com/hp-inc/hp-elitebook-640-g10-notebook-14%22-core-i5-1335u-8-gb-ram-256-gb-ssd-pan-nordic-p3285843",
				},
				home: "https://eshop.edgemo.com/themes/hp-brand-showcase",
				print:
					"https://eshop.edgemo.com/produktkategori/printere-pos/printere?ItemSearchPage-useSearchV2=true&ItemSearchPage-showSearchV2VariantInsteadOfItem=true&ItemSearchPage-Facet%5BIce_dk_Brand%5D=Ice_dk_Brand&ItemSearchPage-FacetValue%5BIce_dk_Brand%5D%5B%5D=HP%2520Inc.",
			},
		},
	},
	"nl-NL": {
		arp: {
			name: "ARP",
			links: {
				products: {
					1: "https://www.arp.nl/hp-wolf-een-nieuwe-generatie/",
					2: "https://www.arp.nl/hp-wolf-een-nieuwe-generatie/",
					3: "https://www.arp.nl/hp-wolf-een-nieuwe-generatie/",
				},
				home: "https://www.arp.nl/partner-hp/",
				print: "https://www.arp.nl/hp-wolf-een-nieuwe-generatie/",
			},
		},
		azerty: {
			name: "Azerty",
			links: {
				products: {
					1: "https://azerty.nl/product/hp-elite-dragonfly-g4-notebook-wolf-pro-security/5850963",
					2: "https://azerty.nl/product/hp-eb840g10-i7-1355u-14-16gb-512-pc/5713596",
					3: "https://azerty.nl/product/hp-eb640g10-i5-1335u-14-16gb-512-pc/5713627",
				},
				home: "https://azerty.nl/category/laptops-en-tablets#!sorting=15&limit=30&view=grid&Merk2=hp",
				print:
					"https://azerty.nl/category/randapparatuur/kantoor/Printers#!sorting=7&limit=9&view=grid&Merk2=hp",
			},
		},
		bechtle: {
			name: "Bechtle NL",
			links: {
				products: {
					1: "https://www.bechtle.com/nl/finder/product-family/hp-dragonfly-g4-laptop--4785--f?query=HP+Elite+Dragonfly+G4",
					2: "https://www.bechtle.com/nl/finder/product-family/hp-elitebook-840-g10-laptop--4796--f?query=HP+EliteBook+840+g10",
					3: "https://www.bechtle.com/nl/finder/product-family/hp-elitebook-640-g10-laptop--4757--f?query=HP+EliteBook+640+g10",
				},
				home: "https://www.bechtle.com/nl/merk/hp",
				print:
					"https://www.bechtle.com/nl/shop/hardware/printen/printen--10009005--c/hp/producttype--multifunctional-mfp",
			},
		},
		// Changed to Dustin, but product links stay the same!!
		centralpoint: {
			name: "Dustin",
			links: {
				products: {
					1: "https://www.dustin.nl/zoeken/dragonfly%20g4",
					2: "https://www.dustin.nl/product/5011343789/elitebook-840-g10",
					3: "https://www.dustin.nl/product/5011343777/elitebook-640-g10",
				},
				home: "https://www.dustin.nl/handelsmerken/hp",
				print:
					"https://www.dustin.nl/handelsmerken/hp/producten/hp-laserprinters",
			},
		},
		hAndR: {
			name: "H&R",
			links: {
				products: {
					1: "https://henr.nl/werkplekinrichting/",
					2: "https://henr.nl/werkplekinrichting/",
					3: "https://henr.nl/werkplekinrichting/",
				},
				home: "https://henr.nl/",
				print: "https://henr.nl/werkplekinrichting/",
			},
		},
		negIT: {
			name: "Neg IT",
			links: {
				products: {
					1: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
					2: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
					3: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
				},
				home: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
				print: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
			},
		},
	},
	"en-NL": {
		arp: {
			name: "ARP",
			links: {
				products: {
					1: "https://www.arp.nl/hp-wolf-een-nieuwe-generatie/",
					2: "https://www.arp.nl/hp-wolf-een-nieuwe-generatie/",
					3: "https://www.arp.nl/hp-wolf-een-nieuwe-generatie/",
				},
				home: "https://www.arp.nl/partner-hp/",
				print: "https://www.arp.nl/hp-wolf-een-nieuwe-generatie/",
			},
		},
		azerty: {
			name: "Azerty",
			links: {
				products: {
					1: "https://azerty.nl/product/hp-elite-dragonfly-g4-notebook-wolf-pro-security/5850963",
					2: "https://azerty.nl/product/hp-eb840g10-i7-1355u-14-16gb-512-pc/5713596",
					3: "https://azerty.nl/product/hp-eb640g10-i5-1335u-14-16gb-512-pc/5713627",
				},
				home: "https://azerty.nl/category/laptops-en-tablets#!sorting=15&limit=30&view=grid&Merk2=hp",
				print:
					"https://azerty.nl/category/randapparatuur/kantoor/Printers#!sorting=7&limit=9&view=grid&Merk2=hp",
			},
		},
		bechtle: {
			name: "Bechtle NL",
			links: {
				products: {
					1: "https://www.bechtle.com/nl/finder/product-family/hp-dragonfly-g4-laptop--4785--f?query=HP+Elite+Dragonfly+G4",
					2: "https://www.bechtle.com/nl/finder/product-family/hp-elitebook-840-g10-laptop--4796--f?query=HP+EliteBook+840+g10",
					3: "https://www.bechtle.com/nl/finder/product-family/hp-elitebook-640-g10-laptop--4757--f?query=HP+EliteBook+640+g10",
				},
				home: "https://www.bechtle.com/nl/merk/hp",
				print:
					"https://www.bechtle.com/nl/shop/hardware/printen/printen--10009005--c/hp/producttype--multifunctional-mfp",
			},
		},
		// Changed to Dustin, but product links stay the same!!
		centralpoint: {
			name: "Dustin",
			links: {
				products: {
					1: "https://www.dustin.nl/zoeken/dragonfly%20g4",
					2: "https://www.dustin.nl/product/5011343789/elitebook-840-g10",
					3: "https://www.dustin.nl/product/5011343777/elitebook-640-g10",
				},
				home: "https://www.dustin.nl/handelsmerken/hp",
				print:
					"https://www.dustin.nl/handelsmerken/hp/producten/hp-laserprinters",
			},
		},
		hAndR: {
			name: "H&R",
			links: {
				products: {
					1: "https://henr.nl/werkplekinrichting/",
					2: "https://henr.nl/werkplekinrichting/",
					3: "https://henr.nl/werkplekinrichting/",
				},
				home: "https://henr.nl/",
				print: "https://henr.nl/werkplekinrichting/",
			},
		},
		negIT: {
			name: "Neg IT",
			links: {
				products: {
					1: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
					2: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
					3: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
				},
				home: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
				print: "https://neg-itsolutions.nl/it-kopen/hp-reseller",
			},
		},
	},
	"fi-FI": {
		Tietokeskus: {
			name: "Tietokeskus",
			links: {
				products: {
					1: "https://tietokeskus.shop/product_info.php?products_id=15500643&cPath=1000_1200_1201",
					2: "https://tietokeskus.shop/product_info.php?products_id=15500627&cPath=1000_1200_1201",
					3: "https://tietokeskus.shop/product_info.php?products_id=14636575&cPath=1000_1200_1201",
				},
				home: "https://tietokeskus.shop",
				print:
					"https://tietokeskus.shop/fi/product/list/587968,584473,652108,849793,849769,869116,849760,651961,587983,849799,849757,587986,869146,566956,849787,868756,869152,869149,869155,587995,849742,869158,613850,438236,588118,588013,614027,613760,609872,721736,721733,727412",
			},
		},
		Dustin: {
			name: "Dustin",
			links: {
				products: {
					1: "https://www.dustin.fi/product/5011338261/dragonfly-g4",
					2: "https://www.dustin.fi/product/5011334457/elitebook-840-g10",
					3: "https://www.dustin.fi/product/5011334445/elitebook-640-g10",
				},
				home: "https://www.dustin.fi/brands/hp/tuotteet/the-wolf",
				print: "https://www.dustin.fi/brands/hp/tuotteet/tulostimet/",
			},
		},
		atea: {
			name: "Atea",
			links: {
				products: {
					1: "https://www.atea.fi/eshop/documents/HP-Elite-Work-Happy",
					2: "https://www.atea.fi/eshop/documents/HP-Elite-Work-Happy",
					3: "https://www.atea.fi/eshop/products/tietokoneet-ja-laitteisto/kannettavat-tietokoneet/kannettavat/?filters=A4_151%7EM_1157_10572%7EN_elitebook%20640",
				},
				home: "https://www.atea.fi/eshop/products/tietokoneet-ja-laitteisto/kannettavat-tietokoneet/kannettavat/?filters=A4_151%7EM_1157_10572%7EN_HP",
				print: "https://www.atea.fi/eshop/products/tulostimet-ja-skannerit/tulostimet/?filters=A3_32%7EMF%7EM_1157_10572%7EN_officejet%20pro",
			},
		},
	},
	"no-NO": {
		Dustin: {
			name: "Dustin",
			links: {
				products: {
					1: "https://www.dustin.no/product/5011338261/dragonfly-g4",
					2: "https://www.dustin.no/product/5011334457/elitebook-840-g10",
					3: "https://www.dustin.no/product/5011334444/elitebook-640-g10",
				},
				home: "https://www.dustin.no/brands/hp/",
				print:
					"https://www.dustin.no/group/hardware/printers-supplies/multi-function-printers/color-laser-printer?manufacturer=hp",
			},
		},
		ecit: {
			name: "Ecit",
			links: {
				products: {
					1: "https://www.ecitshop.no/product_list?query=hp+elite+dragonfly+g4",
					2: "https://www.ecitshop.no/product_list?query=Hp+elitebook+840+g10",
					3: "https://www.ecitshop.no/product_list?query=HP+EliteBook+640+G10",
				},
				home: "https://www.ecitshop.no/product_list/Datamaskiner?inStock=1&manufacturer=HP&page=1&sortBy=0",
				print:
					"https://www.ecitshop.no/product_list/Printer-skanner?inStock=1&manufacturer=HP&page=1&sortBy=0",
			},
		},
		braatheGruppe: {
			name: "Braathe Gruppen",
			links: {
				products: {
					1: "https://webshop.braathe.no/hp-dragonfly-g4-intel-core/cat-p/c/p1003829911",
					2: "https://webshop.braathe.no/hp-elitebook-840-g10-i7-1355u/cat-p/c/p1003760005",
					3: "https://webshop.braathe.no/hp-elitebook-640-g10-intel/cat-p/c/p1003766736",
				},
				home: "https://webshop.braathe.no/datamaskiner/cat-c/c100087?ffd=l-c-36159l-b-34",
				print:
					"https://webshop.braathe.no/skrivere/cat-c/c100089/l_en/s_13?ffd=l-b-34",
			},
		},
		mobit: {
			name: "Mobit",
			links: {
				products: {
					1: "https://nettbutikk.mobit.no/search?q=dragonfly%20g4",
					2: "https://nettbutikk.mobit.no/hp/818l5ea-hash-uuw/hp-elitebook-840-g10-notebook-14-core-i5-1335u-16-gb-ram-256-gb-ssd-pan-nordic-windows-11-pro",
					3: "https://nettbutikk.mobit.no/search?q=elitebook%20640%20g10",
				},
				home: "https://mobit.no/produkt/mer-om-hp/",
				print: "https://mobit.no/produkt/mer-om-hp/",
			},
		},
		advania: {
			name: "Advania",
			links: {
				products: {
					1: "https://eshop.advania.no/hp-dragonfly-g4-intel-core/cat-p/c/p1003829911",
					2: "https://eshop.advania.no/hp-elitebook-840-g10-i5-1335u/cat-p/c/p1003760004",
					3: "https://eshop.advania.no/hp-elitebook-640-g10-intel/cat-p/c/p1003766736",
				},
				home: "https://eshop.advania.no/pc-server/cat-c/c100082?ffd=l-b-34",
				print:
					"https://eshop.advania.no/skrivere/cat-c/c100094/s_13?ffd=l-b-34",
			},
		},
		atea: {
			name: "Atea",
			links: {
				products: {
					1: "https://www.atea.no/eshop/product/hp-dragonfly-g4-notebook-13-5-intel-core-i5-1335u-evo-16-gb-ram-512-gb-ssd-pan-nordic/?prodid=5439984",
					2: "https://www.atea.no/eshop/product/hp-elitebook-840-g10-notebook-14-core-i5-1335u-16-gb-ram-512-gb-ssd-pan-nordic/?prodid=5417277",
					3: "https://www.atea.no/eshop/product/hp-elitebook-640-g10-notebook-14-intel-core-i5-1335u-16-gb-ram-256-gb-ssd-pan-nordic/?prodid=5419088",
				},
				home: "https://www.atea.no/eshop/manufacturers/hp-inc/",
				print:
					"https://www.atea.no/eshop/manufacturers/hp-inc/skrivere-og-skannere/skrivere/",
			},
		},
	},
	"sv-SE": {
		atea: {
			name: "Atea",
			links: {
				products: {
					1: "https://www.atea.se/eshop/product/hp-dragonfly-g4-i5-1335u-13-16gb-512-ml/?prodid=2316479",
					2: "https://www.atea.se/eshop/product/hp-eb-840-g10-i5-1335u-14-16gb-256-ml/?prodid=2316206",
					3: "https://www.atea.se/eshop/product/hp-elitebook-640g9-i5-1235u-14-16gb-256-pc-intel-i5-1235u-14-0-fhd-ag-le/?prodid=2258587",
				},
				home: "https://www.atea.se/om-atea/partnerskap/hp/",
				print:
					"https://www.atea.se/eshop/manufacturers/hp-inc/skrivare-och-scanners/",
			},
		},
		advania: {
			name: "Advania",
			links: {
				products: {
					1: "https://eshop.advania.no/shop/hp-dragonfly-g4-intel-core/cat-p/c/p1003829911/l_sv",
					2: "https://eshop.advania.no/shop/hp-elitebook-840-g10-i5-1335u/cat-p/c/p1003760004/l_sv",
					3: "hhttps://eshop.advania.no/shop/hp-elitebook-640-g10-intel/cat-p/c/p1003766736/l_sv",
				},
				// home: "https://www.dustin.se/brands/hp/",
				// print: "https://www.dustin.se/brands/hp/produkter/skrivare/",
			},
		},
		dustin: {
			name: "Dustin",
			links: {
				products: {
					1: "https://www.dustin.se/product/5011338261/dragonfly-g4",
					2: "https://www.dustin.se/product/5011334457/elitebook-840-g10",
					3: "https://www.dustin.se/product/5011334443/elitebook-640-g10",
				},
				home: "https://www.dustin.se/brands/hp/",
				print: "https://www.dustin.se/brands/hp/produkter/skrivare/",
			},
		},
		b2bItPartner: {
			name: "B2B IT-Partner",
			links: {
				products: {
					1: "https://www.b2bshop.se/product_list?query=HP+Elite+Dragonfly+G4",
					2: "https://www.b2bshop.se/product/Datorer/Barbara-datorer/HP/HP-EliteBook-840-G10-Notebook---14---Core-i5-1335?prodid=8162692",
					//3: "https://www.b2bshop.se/product/Datorer/Barbara-datorer/HP/HP-ProBook-640-G8-Notebook---14---Core-i5-1135G7-?prodid=7177720",
				},
				home: "https://www.b2bitpartner.se/hp-amd/",
				print:
					"https://www.b2bshop.se/product_list/Skrivare-Scanner?inStock=0&manufacturer=HP&page=1",
			},
		},
		addpro: {
			name: "Addpro",
			links: {
				products: {
					1: "https://addstore.se/product/Datorer/Barbara-datorer/HP/HP-Dragonfly-G4-Notebook---13-5---Intel-Core-i5-1?prodid=2449122",
					2: "https://addstore.se/product/Datorer/Barbara-datorer/HP/HP-EliteBook-840-G10-Notebook---14---Core-i5-1335?prodid=2388131",
					3: "https://addstore.se/product/Datorer/Barbara-datorer/HP/HP-EliteBook-640-G10-Notebook---14---Intel-Core-i?prodid=2386775",
				},
				home: "https://www.addpro.se/hp/",
				print: "https://www.addpro.se/hp/",
			},
		},
		rts: {
			name: "RTS",
			links: {
				products: {
					1: "https://shop.rts.se/product/Datorer/Barbara/HP/HP-Dragonfly-G4-Notebook-13-5-Intel-Core-i7-1?prodid=4154948",
					2: "https://shop.rts.se/product/Datorer/Barbara/HP/HP-EliteBook-840-G10-Notebook-14-Intel-Core-i?prodid=4154709",
					3: "https://shop.rts.se/product/Datorer/Barbara/HP/HP-EliteBook-640-G10-Notebook-14-Core-i5-1335?prodid=4134019",
				},
				home: "https://shop.rts.se/product_list/Datorer/Barbara?instock=true&manufacturer=HP&page=1&recommended=false&sortby=0",
				print:
					"https://shop.rts.se/product_list/Skrivare?instock=false&manufacturer=HP&page=1&recommended=false&sortby=0",
			},
		},
	},
}

export const ProductTypes = {
	computer: 0x1,
	service: 0x2,
}

export const products = {
	1: {
		"en-NL": {
			title: "HP Elite Dragonfly G4",
			image: "images/products/Elite-Dragonfly-G10.jpg",
			badge: "images/products/intel-core-i7.png",
			features: {
				1: "Touchscreen and ultralight design",
				2: "Crisp audio and an ultrabright display",
				3: "Intel® 12de generatie Core™ i5 processor",
			},
			productType: ProductTypes.computer,
		},
		"nl-NL": {
			title: "HP Elite Dragonfly G4",
			image: "images/products/Elite-Dragonfly-G10.jpg",
			badge: "images/products/intel-core-i7.png",
			features: {
				1: "Prachtig gemaakt en uiterst licht",
				2: "5MP camera met verschijningsfilter",
				3: "Intel® 12de generatie Core™ i5 processor",
			},
			productType: ProductTypes.computer,
		},
		"nl-BE": {
			title: "HP Elite Dragonfly G4",
			image: "images/products/Elite-Dragonfly-G10.jpg",
			badge: "images/products/intel-core-i7.png",
			features: {
				1: "Prachtig gemaakt en uiterst licht",
				2: "5MP camera met verschijningsfilter",
				3: "Intel® 12de generatie Core™ i7 processor",
			},
			productType: ProductTypes.computer,
		},
		"fr-BE": {
			title: "HP Elite Dragonfly G4",
			image: "images/products/Elite-Dragonfly-G10.jpg",
			badge: "images/products/intel-core-i7.png",
			features: {
				1: "Design ultra léger et moderne",
				2: "Caméra de 5 mégapixels avec filtre d’apparence ",
				3: "12e génération de processeurs Intel® Core™ i7",
			},
			productType: ProductTypes.computer,
		},
		"no-NO": {
			title: "HP Elite Dragonfly G4",
			image: "images/products/Elite-Dragonfly-G10.jpg",
			badge: "images/products/intel-core-i7.png",
			features: {
				1: "Ultralett og moderne design",
				2: "13,5-tommers touchskjerm i 3:2-format",
				3: "12. generasjons Intel® Core™ i5-prosessor",
			},
			productType: ProductTypes.computer,
		},
		"sv-SE": {
			title: "HP Elite Dragonfly G4",
			image: "images/products/Elite-Dragonfly-G10.jpg",
			badge: "images/products/intel-core-i7.png",
			features: {
				1: "Skyddad av HP Wolf Pro Security",
				2: "5 MP kamera med utseendefilter",
				3: "13,5-tums bildskärm med ett sidförhållande på 3:2",
			},
			productType: ProductTypes.computer,
		},
		"fi-FI": {
			title: "HP Elite Dragonfly G4",
			image: "images/products/Elite-Dragonfly-G10.jpg",
			badge: "images/products/intel-core-i7.png",
			features: {
				1: "5 MP:n kamera ehostussuodattimella",
				2: "13,5 tuuman näyttö 3:2-kuvasuhteella",
				3: "Hienostuneesti muotoiltu, ultrakevyt",
				4: "Intel® Core™ i5 -prosessorilla varustettu",
			},
			productType: ProductTypes.computer,
		},
		"da-DK": {
			title: "HP EliteBook 1040 G9",
			image: "images/products/Elite-Dragonfly-G10.jpg",
			badge: "images/products/intel-core-i7.png",
			features: {
				1: "Let og mobil",
				2: "5 MP kamera med HP Auto Frame",
				3: "Væk og lås blot ved din tilstedeværelse",
			},
			productType: ProductTypes.computer,
		},
	},
	2: {
		"en-NL": {
			title: "HP EliteBook 840 G10",
			image: "images/products/Elitebook-840-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "5MP camera with HP Auto Frame",
				2: "14-inch diagonal 16:10 display",
				3: "Protected by Wolf Pro Security Edition",
			},
			productType: ProductTypes.computer,
		},
		"nl-NL": {
			title: "HP EliteBook 840 G10",
			image: "images/products/Elitebook-840-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "5MP-camera met HP Auto Frame",
				2: "AI-gestuurde optimalisatie voor maximale prestaties",
				3: "Beschermd door HP Wolf Pro Security Edition",
			},
			productType: ProductTypes.computer,
		},
		"nl-BE": {
			title: "HP EliteBook 840 G10",
			image: "images/products/Elitebook-840-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Eenvoudig mee te nemen, waar u ook heen gaat.",
				2: "Intel® 11de generatie Core™ i5 processor",
				3: "Beschermd door HP Wolf Security",
			},
			productType: ProductTypes.computer,
		},
		"fr-BE": {
			title: "HP EliteBook 840 G10",
			image: "images/products/Elitebook-840-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Taille de l'écran 35,6 cm (14\")",
				2: "11e génération de processeurs Intel® Core™ i5",
				3: "HP Wolf Security for business",
			},
			productType: ProductTypes.computer,
		},
		"no-NO": {
			title: "HP EliteBook 840 G10",
			image: "images/products/Elitebook-840-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Beskyttet av HP Wolf Security",
				2: "11. generasjons Intel® Core™-prosessor.",
				3: "14-tommers skjerm i 16:9-format",
			},
			productType: ProductTypes.computer,
		},
		"sv-SE": {
			title: "HP EliteBook 840 G10",
			image: "images/products/Elitebook-840-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Skyddad av HP Wolf Pro Security",
				2: "utformad för ert sätt att arbeta med en ny AI-baserad ljudupplevelse.",
				3: "11:e generationens Intel® Core™ i5",
			},
			productType: ProductTypes.computer,
		},
		"fi-FI": {
			title: "HP EliteBook 840 G10",
			image: "images/products/Elitebook-840-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Mahtava suorituskyky, helppo ottaa mukaan",
				2: "Edukseen erottuva, erittäin ohut alumiinirakenne",
				3: "SureView -integroitu näytönsuoja",
				4: "Intel® Core™ i7 -prosessorilla varustettu",
			},
			productType: ProductTypes.computer,
		},
		"da-DK": {
			title: "HP EliteBook x360 1030 G8 ",
			image: "images/products/Elitebook-840-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Beskyttet af HP Wolf Security",
				2: "Nyeste Intel®-processor",
				3: "Højttalere med krystalklar lyd",
			},
			productType: ProductTypes.computer,
		},
	},
	3: {
		"en-NL": {
			title: "HP EliteBook 640 G10",
			image: "images/products/EliteBook-640-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Crystal-clear speakers",
				2: "Latest Intel® processor",
				3: "Protected by HP Wolf Security for Business",
			},
			productType: ProductTypes.computer,
		},
		"nl-NL": {
			title: "HP EliteBook 640 G10",
			image: "images/products/EliteBook-640-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Kristalheldere luidsprekers",
				2: "Nieuwste Intel®-processor",
				3: "Beschermd door HP Wolf Pro Security",
			},
			productType: ProductTypes.computer,
		},
		"nl-BE": {
			title: "HP EliteBook 640 G10",
			image: "images/products/EliteBook-640-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Beschermd door HP Wolf Security for business",
				2: "De compacte, lichte behuizing met aluminium onderdelen is gemakkelijk mee te nemen",
				3: "Intel® 11de generatie Core™ i5 processor",
			},
			productType: ProductTypes.computer,
		},
		"fr-BE": {
			title: "HP EliteBook 640 G10",
			image: "images/products/EliteBook-640-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "HP Wolf Security for business",
				2: "Taille de l'écran 35,6 cm (14\")",
				3: "11e génération de processeurs Intel® Core™ i5",
			},
			productType: ProductTypes.computer,
		},
		"no-NO": {
			title: "HP EliteBook 640 G10",
			image: "images/products/EliteBook-640-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Beskyttet av HP Wolf Security",
				2: "Nyeste Intel®-prosessor",
				3: "Krystallklare høyttalere",
			},
			productType: ProductTypes.computer,
		},
		"sv-SE": {
			title: "HP EliteBook 640 G10",
			image: "images/products/EliteBook-640-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "HP Wolf Security för företag",
				2: 'Skärm diagonal 35,6 cm (14")',
				3: "11:e generationens Intel® Core™ i5",
			},
			productType: ProductTypes.computer,
		},
		"fi-FI": {
			title: "HP EliteBook 640 G10",
			image: "images/products/EliteBook-640-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Intel® -prosessorilla varustettu kannettava",
				2: "Kustannustehokas ja pitkä akunkesto",
				3: "Kristallinkirkas äänenlaatu",
				4: "Intel® Core™ i5 -prosessorilla varustettu",
			},
			productType: ProductTypes.computer,
		},
		"da-DK": {
			title: "HP EliteBook 640 G10",
			image: "images/products/EliteBook-640-G10.jpg",
			badge: "images/products/intel-core-i5.png",
			features: {
				1: "Højttalere med krystalklar lyd",
				2: "Nyeste Intel®-processor",
				3: "Beskyttet af HP Wolf Security",
			},
			productType: ProductTypes.computer,
		},
	},
}

/**
 * Partners
 */

//  -nl-fr
// 		-partner
// 			-links
// 				-pc
// 					-link

/**
 * PCs
 */

// -pc
// 		-available
// 			-nl-fr
// -pc
// -pc
// -pc
