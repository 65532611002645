import { useLocation } from "react-router"
import Product from "./Product"
import { products } from "../PartnerSection/partnersAndComputers"
import { partners } from "../PartnerSection/partnersAndComputers"
import { useEffect, useState } from "react"

import { useStore } from "../../../zustand"

const ProductList = () => {

	const location = useLocation()
	let locale = location.pathname.replace("/", "")

	let randomPartners = ["sv-SE", "fi-FI"]

	const [partner, setPartner] = useState({})

	const getPartner = () => {
		if (randomPartners.indexOf(locale) >= 0) {
			let localPartners = partners[locale]
			var keys = Object.keys(localPartners)
			let p = keys[Math.floor(Math.random() * keys.length)]

			let partner = {}
			partner[p] = localPartners[p]

			setPartner(partner)
			useStore.setState({ partnerLogo: partner })

		} else {
			setPartner(partners[locale])
		}
	}

	useEffect(() => {
		getPartner()
        // eslint-disable-next-line
	}, [])

	const getProduct = (id) => {
		if (products[id]) {
			return <Product
				id={id}
				key={id}
				partners={partner}
				/>
		} else {
			return null
		}
	}

	return (
		<>
			<div className='product-list'>
				<div className='max-width centered'>
					<div className='product-list__items'>
						{Object.keys(products).map(id => getProduct(id))}
					</div>
				</div>
			</div>
		</>
	)
}

export default ProductList
