import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { StyledDialog } from "./styles"
import { FormControlLabel, Checkbox } from "@mui/material"

const axios = require("axios")

const CloseBtn = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24px"
		viewBox="0 0 24 24"
		width="24px"
		fill="#000000"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
	</svg>
)

const HPDialog = ({
	onClose,
	title,
	filePath,
	open,
	contactReason,
	contactOnly, // <- this is used in order to differentiate between the pure contact popup and the request demo popup in order to display another headline for DK
}) => {
	const [showDownloadLink, setShowDownloadLink] = useState(false)

	const [firstName, setfirstName] = useState("")
	const [surName, setSurName] = useState("")
	const [companyName, setCompanyName] = useState("")
	const [email, setEmail] = useState("")
	const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
		useState(false)

	const [errors, setErrors] = useState({})
	const [sending, setSending] = useState(false)
	const [success, setSuccess] = useState(null)

	const { t } = useTranslation()

	const location = useLocation()

	let endpoint =
		(window.location.hostname === "localhost" || window.location.hostname === "int.fe.hp-wolf-security.campaign-loyalty.com")
			? `${
					window.location.protocol + "//" + window.location.hostname
			  }/stage/index.php`
			: `${
					window.location.protocol + "//" + window.location.hostname
			  }/index.php`

	const handleSubmit = e => {
		e.preventDefault()

		setSending(true)
		axios
			.post(endpoint, {
				firstName: firstName,
				surName: surName,
				companyName: companyName,
				email: email,
				country: location.pathname.replace("/", ""),
				contactReason: contactReason,
			})
			.then(function (response) {
				setSending(false)
				if (response.data.errors) {
					Object.keys(response.data.errors.children).forEach(child => {
						if (response.data.errors.children[child].errors) {
							let temp = {}
							temp[child] = response.data.errors.children[child].errors
							setErrors(temp)
						}
					})
				} else {
					window.dataLayer = window.dataLayer || []
					window.dataLayer.push({
						event: contactOnly ? "contactAanvraag" : "demoAanvraag",
					})
					setSuccess(t("generic.form.success"))
					if (filePath) {
						setShowDownloadLink(true)
					}
				}
			})
			.catch(function (error) {
				setSending(false)
				console.log(error)
			})
	}

	// const isCustomConditionDK = 	!contactOnly && location.pathname.replace("/", "") === "da-DK"
	const isCustomConditionFI =
		!contactOnly && location.pathname.replace("/", "") === "fi-FI"

	return (
		<StyledDialog open={open} onClose={onClose}>
			<header>
				<h5 className="title-h5 font-wolf">{title}</h5>
				<button className="close" onClick={() => onClose()}>
					<CloseBtn />
				</button>
			</header>
			{isCustomConditionFI && (
				<div className="subheadline-dk">
					<h3>*Pakollinen tieto</h3>
				</div>
			)}
			<main>
				<form onSubmit={handleSubmit}>
					<div
						className={`form-field ${
							errors && errors["firstName"] ? "error" : ""
						}`}
					>
						<label>{t("generic.form.firstName.label")}</label>
						<input
							type="text"
							onChange={e => setfirstName(e.target.value)}
							value={firstName}
						/>
						{errors && errors["firstName"] && (
							<span className="error">{t("generic.form.firstName.error")}</span>
						)}
					</div>

					<div
						className={`form-field ${
							errors && errors["surName"] ? "error" : ""
						}`}
					>
						<label>{t("generic.form.surName.label")}</label>
						<input
							type="text"
							onChange={e => setSurName(e.target.value)}
							value={surName}
						/>
						{errors && errors["surName"] && (
							<span className="error">{t("generic.form.surName.error")}</span>
						)}
					</div>

					<div
						className={`form-field ${
							errors && errors["companyName"] ? "error" : ""
						}`}
					>
						<label>{t("generic.form.companyName.label")}</label>
						<input
							type="text"
							onChange={e => setCompanyName(e.target.value)}
							value={companyName}
						/>
						{errors && errors["companyName"] && (
							<span className="error">
								{t("generic.form.companyName.error")}
							</span>
						)}
					</div>

					<div
						className={`form-field ${errors && errors["email"] ? "error" : ""}`}
					>
						<label>{t("generic.form.email.label")}*</label>
						<input
							type="text"
							onChange={e => setEmail(e.target.value)}
							value={email}
							required={"required"}
						/>
						{errors && errors["email"] && (
							<span className="error">{t("generic.form.email.error")}</span>
						)}
					</div>

					<FormControlLabel
						control={
							<Checkbox
								onChange={() =>
									setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
								}
								checked={termsAndConditionsAccepted}
							/>
						}
						label={
							<span
								dangerouslySetInnerHTML={{
									__html: t("generic.form.termsAndConditionsAccepted.label"),
								}}
							></span>
						}
					/>

					<div className="submit-container">
						{!success ? (
							<input
								type="submit"
								value={`${t("generic.form.submit")}${sending ? " ..." : ""}`}
								className={`button rounded modal ${
									sending || !termsAndConditionsAccepted ? "disabled" : ""
								}`}
								disabled={
									sending || !termsAndConditionsAccepted ? "disabled" : ""
								}
							/>
						) : (
							<h5 className="title-h5 font-wolf">{success}</h5>
						)}
						{showDownloadLink && (
							<a
								href={filePath}
								target="_blank"
								rel="noreferrer"
								className="button primary rounded"
							>
								Download
							</a>
						)}
					</div>
				</form>
			</main>
		</StyledDialog>
	)
}

export default HPDialog
