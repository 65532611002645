import { useTranslation } from "react-i18next"
import Slider from "react-slick";

const FeatureGrid = props => {
	const { t } = useTranslation()

	let settings = {
		dots: true,
		infinite: true,
		arrows: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			}
		]
	};

	return (
		<div className='feature-grid' id="hfs">

			<div className='max-width centered'>

				<div className='feature-grid__headlines'>
					<h3 className='title-h3 font-wolf text-align-center'>
						{t('featureGrid.title')}
					</h3>
					<p className='description text-align-center'>
						{t('featureGrid.text')}
					</p>
				</div>

				<div>
					<Slider {...settings}>
						<div className='feature-grid__feature'>
							<div className='feature-grid__feature-image'>
								<img
									src='images/features/tab2.png'
									alt={t('featureGrid.1.title')}
								/>
							</div>
							<div className='feature-grid__feature-content'>
								<h4 className='title-h5 font-wolf'>
									{t('featureGrid.1.title')}
								</h4>
								<p className='description'>
									{t('featureGrid.1.text')}
								</p>
							</div>
						</div>

						<div className='feature-grid__feature'>
							<div className='feature-grid__feature-image'>
								<img
									src='images/features/tab8.png'
									alt={t('featureGrid.2.title')}
								/>
							</div>
							<div className='feature-grid__feature-content'>
								<h4 className='title-h5 font-wolf'>
									{t('featureGrid.2.title')}
								</h4>
								<p className='description'>
									{t('featureGrid.2.text')}
								</p>
							</div>
						</div>

						<div className='feature-grid__feature'>
							<div className='feature-grid__feature-image'>
								<img
									src='images/features/tab7.png'
									alt={t('featureGrid.3.title')}
								/>
							</div>
							<div className='feature-grid__feature-content'>
								<h4 className='title-h5 font-wolf'>
									{t('featureGrid.3.title')}
								</h4>
								<p className='description'>
									{t('featureGrid.3.text')}
								</p>
							</div>
						</div>

						<div className='feature-grid__feature'>
							<div className='feature-grid__feature-image'>
								<img
									src='images/features/tab5.png'
									alt={t('featureGrid.4.title')}
								/>
							</div>
							<div className='feature-grid__feature-content'>
								<h4 className='title-h5 font-wolf'>
									{t('featureGrid.4.title')}
								</h4>
								<p className='description'>
									{t('featureGrid.4.text')}
								</p>
							</div>
						</div>

						<div className='feature-grid__feature'>
							<div className='feature-grid__feature-image'>
								<img
									src='images/features/tab3.png'
									alt={t('featureGrid.5.title')}
								/>
							</div>
							<div className='feature-grid__feature-content'>
								<h4 className='title-h5 font-wolf'>
									{t('featureGrid.5.title')}
								</h4>
								<p className='description'>
									{t('featureGrid.5.text')}
								</p>
							</div>
						</div>

						<div className='feature-grid__feature'>
							<div className='feature-grid__feature-image'>
								<img
									src='images/features/tab6.png'
									alt={t('featureGrid.6.title')}
								/>
							</div>
							<div className='feature-grid__feature-content'>
								<h4 className='title-h5 font-wolf'>
									{t('featureGrid.6.title')}
								</h4>
								<p className='description'>
									{t('featureGrid.6.text')}
								</p>
							</div>
						</div>
						<div className='feature-grid__feature'>
							<div className='feature-grid__feature-image'>
								<img
									src='images/features/tab4.png'
									alt={t('featureGrid.7.title')}
								/>
							</div>
							<div className='feature-grid__feature-content'>
								<h4 className='title-h5 font-wolf'>
									{t('featureGrid.7.title')}
								</h4>
								<p className='description'>
									{t('featureGrid.7.text')}
								</p>
							</div>
						</div>

						<div className='feature-grid__feature'>
							<div className='feature-grid__feature-image'>
								<img
									src='images/features/tab1.png'
									alt={t('featureGrid.8.title')}
								/>
							</div>
							<div className='feature-grid__feature-content'>
								<h4 className='title-h5 font-wolf'>
									{t('featureGrid.8.title')}
								</h4>
								<p className='description'>
									{t('featureGrid.8.text')}
								</p>
							</div>
						</div>
					</Slider>
					<div className='feature-grid__feature-hint'>
						<span>*</span> {t('featureGrid.hint')}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FeatureGrid