import { useTranslation } from "react-i18next"
import { privacyLinks } from "../../../config"
import { useState } from "react"
const Footer = ({ locale }) => {
	const { t } = useTranslation()

	const openClasses = "footer__legal-toggle-content open"
	const closedClasses = "footer__legal-toggle-content"
	const togglerOpenClasses = "footer__legal-toggler open"
	const togglerClosedClasses = "footer__legal-toggler"

	const [openState, setOpenState] = useState(false)

	return (
		<footer className="footer">
			<div className="footer__links max-width centered">
				<ul>
					<li>
						<a href={privacyLinks[locale]}>{t("footerLinks.privacy")}</a>
					</li>
				</ul>
			</div>
			<div className="footer__legal max-width centered">
				<div
					className={
						openState === true ? togglerOpenClasses : togglerClosedClasses
					}
					onClick={() => setOpenState(!openState)}
				>
					{t("terms.toggler")}
				</div>

				<div className={openState === true ? openClasses : closedClasses}>
					<p>{t("terms.intro")}</p>
					{Array.from(Array(17).keys()).map(n =>
						// Dirty hack I know
						t(`terms.${n + 1}`).search("terms") ? (
							<p key={n}>{t(`terms.${n + 1}`)}</p>
						) : null
					)}
					<p>
						© Copyright <span id="year"></span> HP Development Company, L.P.{" "}
					</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer
