import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Page from './Page/Page';
import { localeIDs } from '../config'

import '../styles/styles.scss'

const App = () => {
	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<Routes>
				{localeIDs.map(locale =>
					<Route exact path={`/${locale}`} element={<Page />} key={locale}/>
				)}
				<Route path={"*"} element={<Page error={true}/>} />
			</Routes>
		</BrowserRouter>
	)
}

export default App