import i18next from "i18next"
import { localeIDs } from "../../config"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"

import CookieConsent from "react-cookie-consent"

import HTML from "./HTML"

const Page = props => {
	const { i18n, t } = useTranslation()

	let languages = []
	localeIDs.forEach((localeID, index) => {
		languages[index] = localeID.split("-")[0]
	})

	// Route exceeds localeID list
	if (props.error) {
		let localeID = null

		let lng = i18n.language
		// Let i18n auto-detect user agent language
		// eslint-disable-next-line
		while (!lng) {
			lng = i18n.language

			if (lng) {
				// i18n could detect the user agent language

				// Format = xx-XX
				if (lng.indexOf("-") > 0) {
					if (localeIDs.indexOf(lng) > 0) {
						localeID = lng
					} else {
						// Split detected country / language pair apart and try matching to existing localeIDs
						lng = lng.split("-")[0]
						// eslint-disable-next-line
						let langIndex = languages.findIndex(elem => elem === lng)

						// Found matching languge
						if (langIndex >= 0) {
							localeID = localeIDs[langIndex]
						} else {
							// default to english
							localeID = "en-NL"
						}
					}
				} else {
					// Format = xx
					// eslint-disable-next-line
					let langIndex = languages.findIndex(elem => elem === lng)

					// Found matching languge
					if (langIndex >= 0) {
						localeID = localeIDs[langIndex]
					} else {
						// default to english
						localeID = "en-NL"
					}
				}
			} else {
				localeID = "en-NL"
			}

			i18next.changeLanguage(localeID.split("-")[0])
			window.location.pathname = `/${localeID}`
		}
	}

	// Detect language from URL (in order to override the cookie)
	const location = useLocation()
	let urlLocaleParam = location.pathname.replace("/", "")
	let urlLang = urlLocaleParam.split("-")[0]
	let cookieLng = i18n.language

	// Getting the language from the cookie takes a certain amount of time
	while (!cookieLng) {
		cookieLng = i18n.language
		if (urlLang !== cookieLng) {
			i18next.changeLanguage(urlLang)
		}
	}

	return (
		<>
			<HTML />
			<CookieConsent
				onAccept={() => {}}
				buttonText={t("generic.cookieNotification.button")}
				buttonClasses="button primary rounded modal"
				disableStyles={true}
			>
				{t("generic.cookieNotification.text")}
			</CookieConsent>
		</>
	)
}

export default Page
