import styled from "styled-components"
import Dialog from "@mui/material/Dialog"

export const StyledDialog = styled(Dialog)`
	border-radius: 4px;
	box-shadow: none;
	header {
		* {
			color: #000;
		}
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px;
		padding-bottom: 0;
		button.close {
			all: unset;
			display: flex;
			padding: 5px;
			background: #191919;
			border-radius: 5px;
			cursor: pointer;
			margin-left: 20px;
			svg {
				width: 20px;
				height: 20px;
				fill: #fff;
			}
		}
	}

	.subheadline-dk {
		padding: 30px;
		padding-bottom: 0;
		font-weight: bold;
	}

	main {
		padding: 30px;
	}

	form {
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid #e8e8e8;
		padding-top: 30px;

		.form-field {
			width: 100%;
			margin-bottom: 15px;
			* {
				color: #000;
			}
			@media only screen and (min-width: 768px) {
				width: calc(50% - 15px);
				&:nth-child(odd) {
					margin-right: 30px;
				}
			}
			label {
				display: block;
			}
			input {
				border: 1px solid #1c1c1c;
				border-radius: 5px;
				height: 60px;
				width: 100%;
				font-size: 1.25em;
				&:disabled {
					background-color: #ddd;
				}
			}
			span.error {
				color: #f00;
			}

			&.error {
				label {
					color: #f00;
				}
				input {
					border-color: #f00;
				}
			}
		}

		.submit-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 30px;
			input[type="submit"] {
				margin: 0;
			}
		}
	}
`
