import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledLangPicker } from "../LangPicker/styles";
import { StyledLogoAndLanguage } from "../styles";
import HeaderMobile from "../Navigation/HeaderMobile";
import NavigationMobile from "../Navigation/NavigationMobile";
import NavigationDesktop from "../Navigation/NavigationDesktop";
import Popup from "../Popup/Popup";


const HeroImage = (props) => {
	const { t } = useTranslation()
    const [showMenu, setShowMenu] = useState(false)
	const [showPopup, setShowPopup] = useState(false)
	//let heroVideoSource = props.locale ===  "da-DK" ?
	// 	"./hp-static/uploads/videos/hero-dk.mp4" : "https://www8.hp.com/content/dam/sites/worldwide/security/the-wolf-4/the-wolf-ambient-desktop.mp4"

	return (
		<div className='hero'>
			<Popup showPopup={showPopup} setShowPopup={setShowPopup}/>
			<NavigationDesktop/>
			<NavigationMobile showMenu={showMenu} setShowMenu={setShowMenu}/>
			<HeaderMobile setShowMenu={setShowMenu}/>
			<div className='hero__content'>
				<div className={`hero__content-inner text-color-white image-is-${props.imagealignment}`}>
					<div className='hero__image hero__image--bg-img'>
					<div className="form__wrapper-desktop" data-tf-live={t("form.data-link")}></div>
					</div>
					<div className='text-align-left hero__text'>
						<StyledLogoAndLanguage>
							<img
								src='./hp-static/uploads/images/wolf-logo-hero.svg'
								alt='HP WOLF SECURITY'
							/>
							<img
								src='./images/hero/intel.jpg'
								alt='Intel'
								width='100px'
							/>
							<StyledLangPicker />
						</StyledLogoAndLanguage>

						<h2 className='title-h2 font-wolf'>
							<br />
							{t("hero.title")}
						</h2>
						<p className='description'>
							{t("hero.subtitle")}
						</p>
						<p className='description hide_desktop'>
							{t("subheader.text")}
						</p>
						<a
								onClick={() => setShowPopup(true)}
								className='button hide_desktop button-text-small rounded'
								target="_blank"
							>
								{t('subheader.btnText')}
							</a>

						
						<p className='description'>
							{t("hero.webinartext")}
						</p>
					
							<a
								href={t('webinar.ctaurl')}
								className='button button-text-small rounded'
								target="_blank"
							>
								{t('webinar.cta')}
							</a>
							{/* <span className="text-small">{t('webinar.date2')}</span> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default HeroImage