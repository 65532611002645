import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useStore } from "../../../zustand";
import { partners } from "../PartnerSection/partnersAndComputers";
import CTA from "../Shared/CTA/CTA";
import PartnerSection from "../PartnerSection/PartnerSection"

const ProductClickArea = () => {
	const { t } = useTranslation()
	let locale = useLocation().pathname.replace("/", "")

	let printPartner =
		locale === "sv-SE" || locale === "fi-FI"
			? useStore.getState().partnerLogo
			: partners[locale]

	const activeClasses = 'pca__link active'
	const inactiveClasses = 'pca__link'

	const [activeTab, setActiveTab] = useState(0)
	const [imageSrc, setImageSrc] = useState('hp-static/uploads/images/wolf-tab2-hotspots-1.png')

	const clickHandler = (event, image, id) => {
		setImageSrc(image)
		setActiveTab(id)
	}

	return (
		<div className='pca' id='pca'>
			<div className='pca__wrapper max-width centered'>
				<div className='pca__main-image'>
					<img
						src={imageSrc}
						alt=''
					/>

					 <CTA variant='blue' printPartners={printPartner}>
						{t("CTAs.buyNow")}
					</CTA> 
                    <div style={{ marginTop: "35px"}}>

	
                    </div>
				</div>
				<div className='pca__list-wrapper'>
					<ul className='pca__list'>
						<li
							className={activeTab === 0 ? activeClasses : inactiveClasses}
							onClick={event => clickHandler(event, 'hp-static/uploads/images/wolf-tab2-hotspots-1.png', 0)}
						>
							<h5 className='title-h5 font-wolf click-area'>
								{t('productClickArea.features.1.title')}
							</h5>
							<div className='pca__content-wrapper'>
								<div className="pca__content description">
									<img
										className='rounded'
										src='hp-static/uploads/images/wolf-tab2-hotspots-1-secondary.jpg'
										alt=''
									/>
									<br />
									{t('productClickArea.features.1.text')}
								</div>
							</div>
						</li>
						<li
							className={activeTab === 1 ? activeClasses : inactiveClasses}
							onClick={event => clickHandler(event, 'hp-static/uploads/images/wolf-tab2-hotspots-2.png', 1)}
						>
							<h5 className='title-h5 font-wolf click-area'>
								{t('productClickArea.features.2.title')}
							</h5>
							<div className='pca__content-wrapper'>
								<div className="pca__content description">
									<img
										className='rounded'
										src='hp-static/uploads/images/wolf-tab2-hotspots-2-secondary.jpg'
										alt=''
									/>
									<br />
									{t('productClickArea.features.2.text')}
								</div>
							</div>
						</li>
						<li
							className={activeTab === 2 ? activeClasses : inactiveClasses}
							onClick={event => clickHandler(event, 'hp-static/uploads/images/wolf-tab2-hotspots-3.png', 2)}
						>
							<h5 className='title-h5 font-wolf click-area'>
								{t('productClickArea.features.3.title')}
							</h5>
							<div className='pca__content-wrapper'>
								<div className="pca__content description">
									<img
										className='rounded'
										src='hp-static/uploads/images/wolf-tab2-hotspots-3-secondary.jpg'
										alt=''
									/>
									<br />
									{t('productClickArea.features.3.text')}
								</div>
							</div>
						</li>
						<li
							className={activeTab === 3 ? activeClasses : inactiveClasses}
							onClick={event => clickHandler(event, 'hp-static/uploads/images/wolf-tab2-hotspots-4.png', 3)}
						>
							<h5 className='title-h5 font-wolf click-area'>
								{t('productClickArea.features.4.title')}
							</h5>
							<div className='pca__content-wrapper'>
								<div className="pca__content description">
									<img
										className='rounded'
										src='hp-static/uploads/images/wolf-tab2-hotspots-4-secondary.jpg'
										alt=''
									/>
									<br />
									{t('productClickArea.features.4.text')}
								</div>
							</div>
						</li>
						<li
							className={activeTab === 4 ? activeClasses : inactiveClasses}
							onClick={event => clickHandler(event, 'hp-static/uploads/images/wolf-tab2-hotspots-5.png', 4)}
						>
							<h5 className='title-h5 font-wolf click-area'>
								{t('productClickArea.features.5.title')}
							</h5>
							<div className='pca__content-wrapper'>
								<div className="pca__content description">
									<img
										className='rounded'
										src='hp-static/uploads/images/wolf-tab2-hotspots-5-secondary.jpg'
										alt=''
									/>
									<br />
									{t('productClickArea.features.5.text')}
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default ProductClickArea