import { useTranslation } from "react-i18next"

const BigContentArea = () => {
	const { t } = useTranslation()

	return (
		<div className='bca' id="bca">
			<div className='bca__top module-sh-100'>
				<div
					className='bca__top-wrapper module-sh-100-content sh-100_height-is-m sh-100_is-right sh-100_mobile-wrap'
				>
					<img
						src='hp-static/uploads/images/wolf-tab2-hero-m.jpg'
						alt=''
						className='bca__top-mobile-img'
					/>
					<div className='bca__top-content max-width centered'>
						<div className='bca__top-content-box rounded'>
							<img
								src='hp-static/uploads/images/wolf-logo-black.png'
								alt={t('bigContentArea.hero.title')}
							/>
							<h2 className='title-h2 font-wolf'>
								{t('bigContentArea.hero.title')}
							</h2>
							<p className='description'>
								{t('bigContentArea.hero.text')}
							</p>
							<div className='cta-wrapper cta-dark'>
								<a
									href='#pca'
									className='button dark rounded'
								>
									{t('bigContentArea.hero.cta')}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='bca__bottom'>
				<div className='bca__bottom-wrapper max-width centered'>
					<div className='bca__bottom-image'>
						<img
							src='hp-static/uploads/images/wolf-tab2-security.jpg'
							className='rounded'
							alt=''
						/>
					</div>
					<div className='bca__bottom-content text-color-white'>
						<h4 className='title-h4 font-wolf'>
							{t('bigContentArea.sectionOverview.title')}
						</h4>
						<p className='description'>
							{t('bigContentArea.sectionOverview.text')}
						</p>
						<ul className='bca__bottom-cards'>
							<li className='bca__bottom-card'>
								<h5 className='title-h5 font-wolf'>
									{t('bigContentArea.sectionOverview.cards.card1.title')}
								</h5>
								<p className='description'>
									{t('bigContentArea.sectionOverview.cards.card1.text')}
								</p>
							</li>
							<li className='bca__bottom-card'>
								<h5 className='title-h5 font-wolf'>
									{t('bigContentArea.sectionOverview.cards.card2.title')}
								</h5>
								<p className='description'>
									{t('bigContentArea.sectionOverview.cards.card2.text')}
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BigContentArea