import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";


const NavigationDesktop = ({ showMenu, setShowMenu }) => {
    const { t } = useTranslation()
    useEffect(() => {

    });
    return (
        <>
            <div className="nav-desktop">
                <a href="#hfs">{t("navigation.navPoint1")}</a>
                <a href="#partner" >{t("navigation.navPoint2")}</a>
                <a href="#products" >{t("navigation.navPoint3")}</a>
                <a href="#software" >{t("navigation.navPoint4")}</a>
                <a href="#bca">{t("navigation.navPoint5")}</a>
            </div>
        </>
    )
}

export default NavigationDesktop
