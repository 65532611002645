import { useLocation } from "react-router"

import { products } from "../PartnerSection/partnersAndComputers"
import { useTranslation } from "react-i18next"
import CTA from "../Shared/CTA/CTA"
import { ProductTypes } from "../PartnerSection/partnersAndComputers"
// import { contactReasons } from "../../../config"

// eslint-disable-next-line
const Product = ({id, partners}) => {

	const { t } = useTranslation();

	const location = useLocation()
	let currLocaleID = location.pathname.replace("/", "")

	let product = products[id][currLocaleID]

	// const hideRequestDemo = ["nl-BE", "fr-BE", "fi-FI", "no-NO"]

	const getCTA = () => {
		if (product.productType === ProductTypes.computer) {
			return (
				<>
					{/* Request Demo */}
					{/* {!hideRequestDemo.includes(currLocaleID) && (
						<CTA
							variant='blue'
							hasContactForm
							contactReason={contactReasons.requestDemo}
						>
							{t("CTAs.requestDemo")}
						</CTA>
					)} */}

					{/* Buy now */}
					<CTA variant='blue' partners={partners} id={id}>
						{t("CTAs.buyNow")}
					</CTA>
				</>
			)
		} else {
			return (
				<CTA variant='blue' partners={partners} id={id}>
					{t("CTAs.contact")}
				</CTA>
			)
		}
	}

	const getFeatures = (features) => {
		return (
			<ul>
				{Object.values(features).map((feature, i) =>
					<li key={i}>{feature}</li>
				)}
			</ul>
		)
	}

	const getProduct = () => {
		if (!product.productType) {
			console.warn("Missing productType")
			return
		}

		return (
			<div className='product-list__item'>
				<div className='img-container'>
					<img src={product.image} alt={product.title} />
					<img src={product.badge} alt="" className='badge' />
				</div>
				<h4 className='title-h5 font-wolf'>{product.title}</h4>
				{getFeatures(product.features)}

				<div className='cta-container'>{getCTA()}</div>
			</div>
		)
	}

	return getProduct()
}

export default Product