import { useTranslation } from "react-i18next"
import { useState } from "react"

const TabContentImageSwitch = () => {
	const { t } = useTranslation()

	const activeClasses = 'tis__link current-tab'
	const inactiveClasses = 'tis__link'

	const [activeTab, setActiveTab] = useState(0)
	const [imageSrc, setImageSrc] = useState('hp-static/uploads/images/wolf-slidertab-1.jpg')

	const clickHandler = (event, image, id) => {
		setImageSrc(image)
		setActiveTab(id)
	}
	return (
		<div className='tabs-dark tis' id="tis" >
			<div className='tis__links-wrapper line-on-top links-content max-width centered'>
				<ul className="tis__links">
					<li className={activeTab === 0 ? activeClasses : inactiveClasses} onClick={event => clickHandler(event, 'hp-static/uploads/images/wolf-slidertab-1.jpg', 0)}>
						<h5 className='title-h5 text-color-black font-wolf'>
							{t("teaserSection.teaser1.title")}
						</h5>
						{t("teaserSection.teaser1.text") && 
							<p className='description text-color-black'>
								{t("teaserSection.teaser1.text")}
							</p>
						}
						{t("teaserSection.teaser1.list",{ returnObjects: true }) !== "teaserSection.teaser1.list" && 
							<ul className="description">
							{t("teaserSection.teaser1.list",{ returnObjects: true }).map(listItem => <li style={{ listStyle: "disc"}}>{listItem}</li>)}
							</ul>
						}	
					</li>
					<li className={activeTab === 1 ? activeClasses : inactiveClasses} onClick={event => clickHandler(event, 'hp-static/uploads/images/wolf-slidertab-2.jpg', 1)}>
						<h5 className='title-h5 text-color-black font-wolf'>
							{t("teaserSection.teaser2.title")}
						</h5>
						{t("teaserSection.teaser2.text") && 
							<p className='description text-color-black'>
								{t("teaserSection.teaser2.text")}
							</p>
						}
						{t("teaserSection.teaser2.list",{ returnObjects: true }) !== "teaserSection.teaser2.list" && 
							<ul className="description">
							{t("teaserSection.teaser2.list",{ returnObjects: true }).map(listItem => <li style={{ listStyle: "disc"}}>{listItem}</li>)}
							</ul>
						}	
					</li>
					<li className={activeTab === 2 ? activeClasses : inactiveClasses} onClick={event => clickHandler(event, 'hp-static/uploads/images/wolf-slidertab-3.jpg', 2)}>
						<h5 className='title-h5 text-color-black font-wolf'>
							{t("teaserSection.teaser3.title")}
						</h5>
						{t("teaserSection.teaser3.text") && 
							<p className='description text-color-black'>
								{t("teaserSection.teaser3.text")}
							</p>
						}
						{t("teaserSection.teaser3.list",{ returnObjects: true }) !== "teaserSection.teaser3.list" && 
							<ul className="description">
							{t("teaserSection.teaser3.list",{ returnObjects: true }).map(listItem => <li style={{ listStyle: "disc"}}>{listItem}</li>)}
							</ul>
						}	
					</li>
				</ul>
			</div>
		</div>
	)
}

export default TabContentImageSwitch