import { useEffect, useState } from "react"
import { useStore } from "../../../zustand"

const HeaderMobile = ({setShowMenu}) => {

	return (
        <>
            <div className="nav-mobile">
                <div>
                    <div className="nav_btn_wrapper" onClick={() => setShowMenu(true)}>
                        <div className="navrow"></div>
                        <div className="navrow"></div>
                        <div className="navrow"></div>
                    </div>
                </div>
            </div>
        </>
	)
}

export default HeaderMobile
