import styled, { css } from 'styled-components'
import { CountryList } from './LangPicker';
import LangPicker from './LangPicker';

export const StyledLangPicker = styled(LangPicker)`

	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;

	button {
		padding-left: 20px;
		padding-right: 15px;
		margin: 0;
		min-width: auto;
		display: flex;
		align-items: center;
		height: 45px;
		span.fi {
			width: 19.33px;
			height: 14.5px;
		}
		svg {
			width: 14.5px; // As base font size
			height: 14.5px; // As base font size
			margin-left: 15px;
			fill: #5F5F5F;
		}
	}

	.list-container {
		padding-top: 8px;
		position: absolute;
		top: 100%;
		z-index: 2;
		@media only screen and (max-width:376px) {
			right: 0;
		}
		ul {
			background: #fff;
			list-style: none;
			border-radius: 4px;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
			li {
				a {
					display: block;
					min-width: 250px;
					padding: 15px 20px;
					span.name {
						color: #5F5F5F;
						margin-left: 20px;
					}
				}
				&:hover {
					a span.name{
						color: #0096D6;
					}
				}
			}
		}
	}

	&.open {
		button svg {
			fill: #0096D6;
		}
	}

`;

export const StyledCountryList = styled(CountryList)`

	${props => props.relative ? css` position: relative; ` : css` position: absolute; `}

	padding-top: 8px;
	top: 100%;
	z-index: 2;

	@media only screen and (max-width:376px) {
		right: 0;
	}
	ul {
		background: #fff;
		list-style: none;
		border-radius: 4px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
		li {
			a {
				display: block;
				min-width: 250px;
				padding: 15px 20px;
				span.name {
					color: #5F5F5F;
					margin-left: 20px;
				}
			}
			&:hover {
				a span.name{
					color: #0096D6;
				}
			}
		}
	}
`