import { useTranslation } from "react-i18next"

const ImageText = props => {
	const { t } = useTranslation()

	return (
		<div className='section-padding' id="software">
			<div className={`max-width centered image-text image-is-${props.imagealignment}`}>
				<div className='image-text__image'>
					<img src='./images/image-text/wolf-laptop.jpg' alt=""/>
				</div>
				<div className='image-text__text'>
					<div className='image-text__text-subline font-wolf'>
						{t("wolfLaptop.subtitle")}
					</div>
					<div className='title-h2 font-wolf'>
						{t("wolfLaptop.title")}
					</div>
					<div className='description'>
						{t("wolfLaptop.text")}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImageText