import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { partners } from "./partnersAndComputers"

import { useStore } from "../../../zustand"

const PartnerSection = ({headline}) => {
	// Only display partners with logo
	const [validPartners, setValidPartners] = useState(null)

	let locale = useLocation().pathname.replace("/", "")
	let countryCode = locale.split("-")[1]

	useEffect(() => {
		let valPars = []

		let partnerLogos =
			locale === "sv-SE" || locale === "fi-FI"
				? useStore.getState().partnerLogo
				: partners[locale]


		let partnerLogosToCheck = Object.keys(partnerLogos).length

		// eslint-disable-next-line
		Object.keys(partnerLogos).map((partner) => {
			let logoURL = `images/logos/${countryCode}/${partner}.jpg`.toLowerCase()

			fetch(logoURL)
				.then(response => response.blob())
				.then(blob => {
					var reader = new FileReader()
					reader.onload = function () {
						if (this.result.search("data:image") >= 0) {
							valPars.push(partner)
						}
						partnerLogosToCheck--
						if (partnerLogosToCheck === 0) {
							setValidPartners(valPars)
						}
					}
					reader.readAsDataURL(blob)
				})
				.catch(err => {
					console.log(err)
				})
		})
		// eslint-disable-next-line
	}, [useStore.getState().partnerLogo])

	return (
        <>
            <div className="headline__wrapper max-width partner__headline" id="partner">
                <h5 className='title-h5 text-color-black font-wolf '>{headline}</h5>
            </div>
            <div className="partner">

                {validPartners
                    ? validPartners.map((partner, i) => {
                        let logoURL =
                        `images/logos/${countryCode}/${partner}.jpg`.toLowerCase()
                        let altTag = partners[locale][partner]["name"]
                        let linkURL = partners[locale][partner]["links"]["home"]
                        return (
                            <a
                            href={linkURL}
                            title={altTag ? altTag : "Partner Logo"}
                            key={i}
                            target={"_blank"}
                            >
                                    <img src={logoURL} alt={altTag ? altTag : "Partner Logo"} />
                                </a>
                            )
                        })
                        : null}
            </div>
        </>
	)
}

export default PartnerSection
